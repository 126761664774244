/* Library level css overide */
#date-range-picker-start,
#date-range-picker-end {
    color: var(--color-primary-dark);
    font-size: var(--font-size-tooltip);
    font-weight: var(--font-weight-body);
    text-overflow: ellipsis;
    max-width: 110px;
    height: 38px;
    font-family: inherit;
}

#date-range-picker-start::placeholder,
#date-range-picker-end::placeholder {
    color: var(--color-mid-grey);
}

#date-range-picker .DateRangePicker {
    width: 100%;
}

#date-range-picker .DateRangePickerInput__showClearDates {
    display: flex;
    align-items: center;
}

#date-range-picker .DateRangePickerInput_clearDates {
    display: flex;
    margin: 0;
    padding: 0;
    right: 8px;
}

#date-range-picker .DateRangePickerInput_calendarIcon {
    display: inline-flex;
    margin-left: auto;
    margin-right: 10px;
    padding: 0;
}

#date-range-picker.rtl .DateRangePickerInput_clearDates {
    right: auto;
    left: 8px;
}

#date-range-picker.rtl .DateRangePickerInput_calendarIcon {
    margin-left: 35px;
    margin-right: auto;
}

#date-range-picker .CalendarDay__selected_span,
#date-range-picker .CalendarDay__hovered_span {
    background: var(--color-light-grey);
    border: 1px double var(--color-light-grey);
    color: var(--color-primary-dark);
}

#date-range-picker .CalendarDay__selected_span:active,
#date-range-picker .CalendarDay__selected_span:hover {
    background: var(--color-light-deeper-grey);
    border: 1px double var(--color-light-deeper-grey);
}

input.DateInput_input {
    font-size: inherit;

}

div.DateInput {
    border-radius: 3px;
    width: auto;
}

input.DateInput_input__focused {
    border-bottom: 2px solid var(--color-transparent);
}

td.CalendarDay__selected,
td.CalendarDay__selected:active,
td.CalendarDay__selected:hover {
    background: var(--color-primary-dark);
    border: 1px double var(--color-primary-dark);
    color: #fff;
}
